import { forwardRef } from "react";
import { View, Text } from "@aws-amplify/ui-react";
import ReCAPTCHA from "react-google-recaptcha";

type PeRecaptchaProps = {
  readonly onExpired: any;
  readonly onChange: any;
  readonly error?: string | undefined;
};

const { VITE_APP_GOOGLE_API_KEY_V2: ggApiKeyV2 } = (import.meta as any).env;

export const ReCaptchaV2 = forwardRef<ReCAPTCHA, PeRecaptchaProps>(
  (props, ref) => {
    return (
      <View as="div" ariaLabel="ReCaptchaV2" maxWidth="100%">
        <ReCAPTCHA
          ref={ref}
          sitekey={ggApiKeyV2}
          onChange={props.onChange}
          onExpired={props.onExpired}
          size="normal"
        />
        {props.error && (
          <Text variation="error" fontSize="0.86em">
            {props.error}
          </Text>
        )}
      </View>
    );
  }
);

import { Stack } from "@mui/material";

export function PeLoading() {
  return (
    <Stack sx={{ width: '100%', height: '100%', minHeight: 100, minWidth: 100 }}
      bgcolor='rgba(255, 255, 255, 0.8)'
      alignContent='center'
      alignItems='center'
      justifyContent='center'>
      <img src='/logo_pure_200x200.gif' style={{ width: 80, height: 80 }} />
    </Stack>
  );
}
import { createContext } from "react";
import { AuthUser } from "@aws-amplify/auth";

type AuthUserContextType = {
  user: AuthUser & {
    username?: string;
    attributes?: {
      email: string;
      phone_number: string;
      [key: string]: string;
    };
  };
  signOut: () => void;
};
export const AuthUserContext = createContext<AuthUserContextType | null>(null);
import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles.css';
import "@aws-amplify/ui-react/styles.css";

import * as Sentry from '@sentry/react';
import AppRoutes from './AppRoutes';

if (import.meta.env.VITE_APP_MODE !== 'local') {
  Sentry.init({
    dsn: "https://f5566677cc73473597b16ed7b3907cf4@o4504687284191232.ingest.sentry.io/4504687292055552",
    integrations: [Sentry.browserTracingIntegration],
    //set this to 0 so that we don't record any performance data (expensive)
    tracesSampleRate: 0,
  });
}


ReactDOM
  .createRoot(document.getElementById('root') as HTMLElement)
  .render(<AppRoutes />);

import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Stack,
} from "@mui/material";

import { useUserData } from "@pe/auth";
import { useIsMobileLayout, useTitle } from "@pe/utils-react";

import { Wrapper } from "src/Wrapper";
import { lightTheme } from "src/theme";

import { BsCaretUp } from 'react-icons/bs';

const CalendarDialog = React.lazy(async () => ({ default: (await import('./dialogs/CalendarDialog')).CalendarDialog }))
const DialogProvider = React.lazy(async () => ({ default: (await import('./dialogs/DialogContext')).DialogProvider }))
const TopBar = React.lazy(async () => import("./topbar/TopBar"));

const MIN_WIDTH = 76;
const MAX_WIDTH = 250;
const TOP_BAR_HEIGHT = 65;

interface FrameContextType {
  compact: boolean;
  menuWidth: number;
  topBarHeight: number;
  setOpenMenu: (v: boolean) => void;
  setOpenCalendar: (v: boolean) => void;
}

const FrameContext = React.createContext<FrameContextType>({
  compact: false,
  menuWidth: 0,
  topBarHeight: 0,
  setOpenMenu: () => { },
  setOpenCalendar: () => { }
});

export const useFrame = () => {
  return useContext(FrameContext);
}

export const Frame = (props: { children?: React.ReactNode; }) => {
  const userData = useUserData();
  const isMobileLayout = useIsMobileLayout();
  const mySection = React.createRef<HTMLDivElement>();
  const [compact, setCompact] = React.useState<boolean>(true);
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);
  const [goTop, setGoTop] = React.useState<boolean>(false);
  const [menuWidth, setMenuWidth] = React.useState<number>(MAX_WIDTH);
  const topBarHeight = TOP_BAR_HEIGHT;

  React.useEffect(() => {
    if (compact) setMenuWidth(MIN_WIDTH)
    else setMenuWidth(MAX_WIDTH)
  }, [compact])


  const handleScroll = () => {
    if (mySection.current) {
      // Get mySection current value 
      const { scrollTop } = mySection.current;
      // Set go top
      setGoTop(scrollTop > 100);
    }
  }

  const handleGoTopClick = () => {
    if (mySection.current) {
      mySection.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  const drawerClickHandle = () => {
    if (isMobileLayout) {
      setOpenMenu(prev => !prev);
    } else {
      setCompact(prev => !prev);
    }
  }

  const [openCalendarDialog, setOpenCalendarDialog] = useState(false);
  useTitle("Pearl");

  return (
    <Wrapper theme={lightTheme}>
      <CalendarDialog
        open={openCalendarDialog}
        fullWidth
        maxWidth='xl'
        onClose={() => setOpenCalendarDialog(false)} />
      <DialogProvider>
        <FrameContext.Provider value={{
          compact,
          menuWidth,
          topBarHeight,
          setOpenMenu,
          setOpenCalendar: setOpenCalendarDialog
        }}>
          <Box component='div'
            overflow='hidden'
            position='relative'
            maxWidth='100%'
            width='100%'
            height='100%'
            bgcolor='#f9f8f8'>
            <Stack direction='row' width='100%' height='100%'>
              <Stack direction='column' flexGrow={1} position='relative' >
                <TopBar drawerClick={drawerClickHandle} compact={compact} userData={userData} />
                <Box component="div" flexGrow={1} position='relative' overflow='auto'>
                  <Box component='div' position='absolute'
                    width='100%'
                    height='100%'
                    maxHeight='100%'
                    maxWidth='100%'
                    overflow='auto'
                    onScroll={handleScroll}
                    ref={mySection}>
                    {props.children}
                    {goTop &&
                      <Button variant='contained'
                        sx={{
                          p: 0,
                          m: 0,
                          animation: '$fade-up 1.5s linear infinite',
                          minWidth: '40px',
                          height: '40px',
                          position: 'fixed',
                          bottom: '35px',
                          right: '28px',
                          transition: 'all .5s ease',
                          zIndex: 1000,
                          bgcolor: '#1c1820',
                        }}
                        onClick={handleGoTopClick}
                      >
                        <BsCaretUp />
                      </Button>}
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </FrameContext.Provider>
      </DialogProvider>
    </Wrapper>
  )
}

export default Frame;

import { ReactNode, Fragment } from "react";
import {
  defaultDarkModeOverride,
  Authenticator,
  ThemeProvider as AwsThemeProvider,
} from "@aws-amplify/ui-react";
import { AuthenticatorHeader } from "./AuthenticatorHeader";
import { AuthenticatorFooter } from "./AuthenticatorFooter";
import { SignupFormFields } from "./SignupFormFields";
import { AuthUserContext } from "./AuthUserContext";
import { AuthType, getAuthType } from "./getAuthType";

const services = {
  async validateCustomSignUp(formData: any) {
    let err = {};
    if (!formData.acknowledgement)
      err = {
        ...err,
        acknowledgement: "You must agree to the Terms & Conditions",
      };
    if (!formData.mycaptcha)
      err = { ...err, mycaptcha: "You must pass the captcha" };
    return err;
  },
};

export const AuthenticationGuard = (props: { children?: ReactNode }) => {
  switch (getAuthType()) {
    case AuthType.COGNITO:
      return (
        <AwsThemeProvider
          theme={{
            name: "my-theme",
            tokens: {
              colors: {
                brand: {
                  primary: {
                    10: { value: "{colors.blue.10}" },
                    20: { value: "{colors.blue.20}" },
                    40: { value: "{colors.blue.40}" },
                    60: { value: "{colors.blue.60}" },
                    80: { value: "{colors.blue.80}" },
                    90: { value: "{colors.blue.90}" },
                    100: { value: "{colors.blue.100}" },
                  },
                },
              },
            },
            overrides: [defaultDarkModeOverride],
          }}
          colorMode="light"
        >
          <Authenticator
            hideSignUp
            components={{
              Header: AuthenticatorHeader,
              Footer: AuthenticatorFooter,
              SignUp: { FormFields: SignupFormFields },
            }}
            services={services}
            loginMechanisms={["email"]}
          >
            {({ signOut, user }) =>
              user && signOut ? (
                <AuthUserContext.Provider value={{ user, signOut }}>
                  {props.children}
                </AuthUserContext.Provider>
              ) : (<Fragment />)
            }
          </Authenticator>
        </AwsThemeProvider>
      );
    case AuthType.AUTHELIA:
    default:
      return props.children;
  }
}

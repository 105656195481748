import { Amplify } from 'aws-amplify';
import { AuthType } from './getAuthType';

export const configureAuth = (config: {
  type: AuthType.AUTHELIA
}
  |
{
  type: AuthType.COGNITO;
  userPoolId: string;
  region: string;
  userPoolWebClientId: string;
}) => {
  switch (config.type) {
    case AuthType.AUTHELIA:
      break;
    case AuthType.COGNITO:
      Amplify.configure({
        Auth: {
          Cognito: {
            userPoolClientId: config.userPoolWebClientId,
            userPoolId: config.userPoolId
          },
          // user_pool_id: config.userPoolId,
          // aws_region: config.region,
          // user_pool_client_id: config.userPoolWebClientId
        }
      });
      break;
  }
};

export enum AuthType {
  COGNITO = "cognito",
  AUTHELIA = "authelia"
}

export const getAuthType = () => {
  switch ((import.meta as any).env.VITE_APP_AUTH_BACKEND) {
    case 'authelia':
      return AuthType.AUTHELIA;
    case 'cognito':
    default:
      return AuthType.COGNITO;
  }
}
import { forwardRef, useState } from "react";
import { InputBase, IconButton, styled, alpha } from "@mui/material";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";

const InputWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  borderBottom: "1px inset #EDF2FF",
  backgroundColor: alpha("#F8F8FF", 1),
  "&:hover": {
    backgroundColor: alpha("#EDF2FF", 1),
  },
  marginLeft: 0,
  width: "100%",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  fontSize: "0.875rem",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.2),
    borderRadius: theme.shape.borderRadius,
    borderBottom: "2px inset transparent",
    "&:focus": {
      backgroundColor: alpha("#EDF2FF", 1),
      borderBottom: "2px inset #DAE4FF",
    },
  },
  "& .Mui-disabled": {
    cursor: "not-allowed",
  },
}));

export const PeInputStyled = forwardRef((props: any, ref) => {
  const [showText, setShowText] = useState(false);
  const [focus, setFocus] = useState(false);
  const { type, disabled } = props;

  return (
    <InputWrapper
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
    >
      {type === "password" && focus && !disabled && (
        <IconButton
          sx={{
            position: "absolute",
            right: 0,
            top: 1,
            zIndex: 10,
          }}
          onClick={() => setShowText((prev) => !prev)}
        >
          {showText ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      )}
      <StyledInputBase
        inputRef={ref}
        {...props}
        type={type === "password" ? (showText ? "text" : "password") : type}
      />
    </InputWrapper>
  );
});

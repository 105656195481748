import React from 'react';

export {Frame} from './components/Frame';

// export const Frame = React.lazy(() => import("./components/Frame"));

export const SolarPage = React.lazy(() => import("./pages/solar/solar.[id]"));
export const ProductImagesListPage = React.lazy(() => import("./pages/product-images"));
export const ProductDesignPage = React.lazy(() => import("./pages/image-design/image-placement.[id]"));
export const ProductImagesManagePage = React.lazy(() => import("./pages/product-images.[id]"));
export const ConfigurationPage = React.lazy(() => import("./pages/configuration"));
export const SearchGlobal = React.lazy(() => import("./pages/search-global"));
export const DashboardPage = React.lazy(() => import("./pages/dashboard"));
export const QuotesPage = React.lazy(() => import("./pages/quotes/quotes"));
export const AccountsPage = React.lazy(() => import("./pages/accounts/accounts"));
export const AccountDetailsPage = React.lazy(() => import("./pages/accounts/accounts.[id]"));
export const AccountCreatePage = React.lazy(() => import("./pages/accounts/create"));
export const LeadsPage = React.lazy(() => import("./pages/leads/leads"));
export const LeadDetailsPage = React.lazy(() => import("./pages/leads/lead.[id]"));
export const LeadCreatePage = React.lazy(() => import("./pages/leads/create"));
export const ProductsPage = React.lazy(() => import("./pages/products/products"));
export const ProductDetailsPage = React.lazy(() => import("./pages/products/product.[id]"));
export const ProductCreatePage = React.lazy(() => import("./pages/products/product.create"));
export const ProductBrandsPage = React.lazy(() => import("./pages/product-brands/product-brand"));
export const ProductBrandDetailsPage = React.lazy(() => import("./pages/product-brands/product-brand.[id]"));
export const ProductBrandCreatePage = React.lazy(() => import("./pages/product-brands/product-brand.create"));
export const EmailsPage = React.lazy(() => import("./pages/emails/emails"));
export const EmailDetailsPage = React.lazy(() => import("./pages/emails/email.[id]"));
export const NotFoundPage = React.lazy(() => import("./pages/404"));
export const SettingPage = React.lazy(() => import("./pages/settings/settings"));
export const QuoteDetailsPage = React.lazy(() => import("./pages/quotes/quotes.[id]"));
export const UsersPage = React.lazy(() => import("./pages/users"));
export const ContactsPage = React.lazy(() => import("./pages/contact/contacts"));
export const ContactDetailsPage = React.lazy(() => import("./pages/contact/contacts.[id]"));
export const ContactCreatePage = React.lazy(() => import("./pages/contact/create"));
export const AddressPage = React.lazy(() => import("./pages/address/address"));
export const AddressCreatePage = React.lazy(() => import("./pages/address/create"));
export const AddressDetailPage = React.lazy(() => import("./pages/address/address.[id]"));
export const ServiceCasesPage = React.lazy(() => import("./pages/service-cases/service-cases"));
export const ServiceCaseDetailsPage = React.lazy(() => import("./pages/service-cases/service-cases.[id]"));
export const ServiceCaseCreatePage = React.lazy(() => import("./pages/service-cases/create"));
export const SMTPConfigsPage = React.lazy(() => import("./pages/smtp-config/smtp-configs"));
export const SMTPConfigDetailsPage = React.lazy(() => import("./pages/smtp-config/smtp-config.[id]"));
export const SMTPConfigCreatePage = React.lazy(() => import("./pages/smtp-config/create"));
export const JobResourceLookupPage = React.lazy(() => import("./pages/job-resource-lookup"));

export const QuotePresetPage = React.lazy(() => import("./pages/quote-presets/quote-preset"));
export const QuotePresetDetailsPage = React.lazy(() => import("./pages/quote-presets/quote-preset.[id]"));
export const EmailTemplatesPage = React.lazy(() => import("./pages/email-templates/email-templates"));
export const EmailTemplateCreatePage = React.lazy(() => import("./pages/email-templates/create"));
export const EmailTemplateDetailsPage = React.lazy(() => import("./pages/email-templates/email-template.[id]"));
export const NoteTemplatesPage = React.lazy(() => import("./pages/note-templates/note-templates"));
export const NoteTemplateDetailsPage = React.lazy(() => import("./pages/note-templates/note-template.[id]"));
export const NoteTemplateCreatePage = React.lazy(() => import("./pages/note-templates/create"));
export const SMSTemplatesPage = React.lazy(() => import("./pages/sms-templates/sms-templates"));
export const SMSTemplateCreatePage = React.lazy(() => import("./pages/sms-templates/create"));
export const SMSTemplateDetailsPage = React.lazy(() => import("./pages/sms-templates/sms-templates.[id]"));
export const DnspApprovalsPage = React.lazy(() => import("./pages/dnsp-approvals/dnsp-approvals"));
export const DnspApprovalCreatePage = React.lazy(() => import("./pages/dnsp-approvals/create"));
export const DnspApprovalDetailsPage = React.lazy(() => import("./pages/dnsp-approvals/dnsp-approval.[id]"));
export const QuoteConfigPage = React.lazy(() => import("./pages/quote-config/QuoteConfig"));


export const ImportOpenLayerMap = React.lazy(async () => {
  const module = await import("@pe/tool-import-openlayer");
  return { default: module.ImportOpenLayerMap };
})
import { AuthType, configureAuth } from "@pe/auth";

const env = (import.meta as any).env

switch (env.VITE_APP_AUTH_BACKEND) {
  case 'authelia': {
    configureAuth({
      type: AuthType.AUTHELIA
    });
  } break;
  case 'cognito':
  default: {
    configureAuth({
      type: AuthType.COGNITO,
      userPoolId: env.VITE_APP_USER_POOL_ID,
      region: env.VITE_APP_REGION,
      userPoolWebClientId: env.VITE_APP_USER_POOL_WEB_CLIENT_ID
    });
  } break;
}

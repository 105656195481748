import { createTheme, outlinedInputClasses } from "@mui/material";
import { deepPurple, indigo } from "@mui/material/colors";

declare module '@mui/material' {
  interface Theme {
    alternatePaperBoxShadow?: string;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    alternatePaperBoxShadow?: string;
  }
}

const alternatePaperBoxShadow = "transparent 0px 2px 4px,rgba(0,0,0,.1) 0px 2px 6px";

export const darkTheme = createTheme({ 
  palette: { mode: "dark" },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 15,
  },
  alternatePaperBoxShadow
});

export const lightTheme = createTheme({
  alternatePaperBoxShadow,
  typography: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 15,
    h6: {
      "@media (max-width:600px)": {
        fontSize: "1rem",
      },
    },
  },
  palette: {
    mode: "light",
    primary: { main: indigo[500], dark: indigo[900], light: indigo[300] },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          fontSize: "1rem",
          paddingBottom: 8,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "1rem",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: indigo[500],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#dde1ef',
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: deepPurple[500],
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: indigo[500],
          },
        },
      },
    },
  },
});
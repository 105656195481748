import { View, Text, useTheme } from "@aws-amplify/ui-react";

export const AuthenticatorFooter = () => {
  const { tokens } = useTheme();
  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Text color={tokens.colors.neutral[80]}>
        Pure Electric &copy; All Rights Reserved
      </Text>
    </View>
  );
};

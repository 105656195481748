import * as React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { AuthenticationGuard } from "@pe/auth";

import './auth-init';

import { Wrapper } from "./Wrapper";
import { darkTheme } from "./theme";
import { useGoogleMapsApi } from "./helpers/useGoogleMapsApi";

import {
  Frame,

  SolarPage,
  ProductImagesListPage,
  ProductDesignPage,
  ProductImagesManagePage,
  ConfigurationPage,
  SearchGlobal,
  DashboardPage,
  QuotesPage,
  AccountsPage,
  AccountDetailsPage,
  AccountCreatePage,
  LeadsPage,
  LeadDetailsPage,
  LeadCreatePage,
  ProductsPage,
  ProductDetailsPage,
  ProductCreatePage,
  ProductBrandsPage,
  ProductBrandDetailsPage,
  ProductBrandCreatePage,
  EmailsPage,
  EmailDetailsPage,
  NotFoundPage,
  SettingPage,
  QuoteDetailsPage,
  UsersPage,
  ContactsPage,
  ContactDetailsPage,
  ContactCreatePage,
  AddressPage,
  AddressCreatePage,
  AddressDetailPage,
  ServiceCasesPage,
  ServiceCaseDetailsPage,
  ServiceCaseCreatePage,
  SMTPConfigsPage,
  SMTPConfigDetailsPage,
  SMTPConfigCreatePage,
  JobResourceLookupPage,

  QuotePresetPage,
  QuotePresetDetailsPage,
  EmailTemplatesPage,
  EmailTemplateCreatePage,
  EmailTemplateDetailsPage,
  NoteTemplatesPage,
  NoteTemplateDetailsPage,
  NoteTemplateCreatePage,
  SMSTemplatesPage,
  SMSTemplateCreatePage,
  SMSTemplateDetailsPage,
  DnspApprovalsPage,
  DnspApprovalCreatePage,
  DnspApprovalDetailsPage,

  QuoteConfigPage,
} from './lazy-exports'

const AppRoutes = () => {
  useGoogleMapsApi();

  return (
    <AuthenticationGuard>
      <BrowserRouter>
        <React.Suspense>
          <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
            <Wrapper theme={darkTheme}>
              <Routes>
                <Route
                  path="/solar/:id"
                  element={<SolarPage />} />
                <Route
                  path="/configuration"
                  element={<ConfigurationPage />} />
                <Route
                  path="/product-images"
                  element={<ProductImagesListPage />} />
                <Route
                  path="/product-images/:id"
                  element={<ProductImagesManagePage />} />
                <Route
                  path="/image-placement/:id"
                  element={<ProductDesignPage />} />

                {/** Wrap all Route under Frame element */}
                <Route
                  path="/"
                  element={
                    <Frame>
                      <Navigate to="/dashboard" />
                    </Frame>} />
                <Route
                  path="*"
                  element={
                    <Frame>
                      <NotFoundPage />
                    </Frame>} />
                <Route
                  path="/search"
                  element={
                    <Frame>
                      <SearchGlobal />
                    </Frame>} />
                <Route
                  path="/quotes"
                  element={
                    <Frame>
                      <QuotesPage />
                    </Frame>} />
                <Route
                  path="/quote/:id"
                  element={
                    <Frame>
                      <QuoteDetailsPage />
                    </Frame>} />
                <Route
                  path="/accounts"
                  element={
                    <Frame>
                      <AccountsPage />
                    </Frame>} />
                <Route
                  path="/account/:id"
                  element={
                    <Frame>
                      <AccountDetailsPage />
                    </Frame>} />
                <Route
                  path="/accounts/create"
                  element={
                    <Frame>
                      <AccountCreatePage />
                    </Frame>} />
                <Route
                  path="/leads"
                  element={
                    <Frame>
                      <LeadsPage />
                    </Frame>} />
                <Route
                  path="/lead/:id"
                  element={
                    <Frame>
                      <LeadDetailsPage />
                    </Frame>} />
                <Route
                  path="/leads/create"
                  element={
                    <Frame>
                      <LeadCreatePage />
                    </Frame>} />
                <Route
                  path="/products"
                  element={
                    <Frame>
                      <ProductsPage />
                    </Frame>} />
                <Route
                  path="/product/:id"
                  element={
                    <Frame>
                      <ProductDetailsPage />
                    </Frame>} />
                <Route
                  path="/products/create"
                  element={
                    <Frame>
                      <ProductCreatePage />
                    </Frame>} />
                <Route
                  path="/productBrands"
                  element={
                    <Frame>
                      <ProductBrandsPage />
                    </Frame>} />
                <Route
                  path="/productBrand/:id"
                  element={
                    <Frame>
                      <ProductBrandDetailsPage />
                    </Frame>} />
                <Route
                  path="/productBrands/create"
                  element={
                    <Frame>
                      <ProductBrandCreatePage />
                    </Frame>} />
                <Route
                  path="/emails"
                  element={
                    <Frame>
                      <EmailsPage />
                    </Frame>} />
                <Route
                  path="/email/:id"
                  element={
                    <Frame>
                      <EmailDetailsPage />
                    </Frame>} />
                <Route
                  path="/quote-config"
                  element={
                    <Frame>
                      <QuoteConfigPage />
                    </Frame>} />
                <Route
                  path="/dashboard"
                  element={
                    <Frame>
                      <DashboardPage />
                    </Frame>} />
                <Route
                  path="/users"
                  element={
                    <Frame>
                      <UsersPage />
                    </Frame>} />
                <Route
                  path="/contacts"
                  element={
                    <Frame>
                      <ContactsPage />
                    </Frame>} />
                <Route
                  path="/contact/:id"
                  element={
                    <Frame>
                      <ContactDetailsPage />
                    </Frame>} />
                <Route
                  path="/contacts/create"
                  element={
                    <Frame>
                      <ContactCreatePage />
                    </Frame>} />
                <Route
                  path="/address"
                  element={
                    <Frame>
                      <AddressPage />
                    </Frame>} />
                <Route
                  path="/addresses/create"
                  element={
                    <Frame>
                      <AddressCreatePage />
                    </Frame>} />
                <Route
                  path="/address/:id"
                  element={
                    <Frame>
                      <AddressDetailPage />
                    </Frame>} />
                <Route
                  path="/settings"
                  element={
                    <Frame>
                      <SettingPage />
                    </Frame>} />
                <Route
                  path="/service-cases"
                  element={
                    <Frame>
                      <ServiceCasesPage />
                    </Frame>} />
                <Route
                  path="/serviceCase/:id"
                  element={
                    <Frame>
                      <ServiceCaseDetailsPage />
                    </Frame>} />
                <Route
                  path="/service-cases/create"
                  element={
                    <Frame>
                      <ServiceCaseCreatePage />
                    </Frame>} />
                <Route
                  path="/smtp-configs"
                  element={
                    <Frame>
                      <SMTPConfigsPage />
                    </Frame>} />
                <Route
                  path="/smtpConfig/:id"
                  element={
                    <Frame>
                      <SMTPConfigDetailsPage />
                    </Frame>} />
                <Route
                  path="/smtp-config/create"
                  element={
                    <Frame>
                      <SMTPConfigCreatePage />
                    </Frame>} />
                <Route
                  path="/setting/job-resource-lookup"
                  element={
                    <Frame>
                      <JobResourceLookupPage />
                    </Frame>} />
                <Route
                  path="/quote-presets"
                  element={
                    <Frame>
                      <QuotePresetPage />
                    </Frame>} />
                <Route
                  path="/quote-preset/:category"
                  element={
                    <Frame>
                      <QuotePresetDetailsPage />
                    </Frame>} />
                <Route
                  path="/email-templates"
                  element={
                    <Frame>
                      <EmailTemplatesPage />
                    </Frame>} />
                <Route
                  path="/email-template/create"
                  element={
                    <Frame>
                      <EmailTemplateCreatePage />
                    </Frame>} />
                <Route
                  path="/email-template/:id"
                  element={
                    <Frame>
                      <EmailTemplateDetailsPage />
                    </Frame>} />
                <Route
                  path="/note-templates"
                  element={
                    <Frame>
                      <NoteTemplatesPage />
                    </Frame>} />
                <Route
                  path="/note-template/:id"
                  element={
                    <Frame>
                      <NoteTemplateDetailsPage />
                    </Frame>} />
                <Route
                  path="/note-template/create"
                  element={
                    <Frame>
                      <NoteTemplateCreatePage />
                    </Frame>} />
                <Route
                  path="/sms-templates"
                  element={
                    <Frame>
                      <SMSTemplatesPage />
                    </Frame>} />
                <Route
                  path="/sms-template/create"
                  element={
                    <Frame>
                      <SMSTemplateCreatePage />
                    </Frame>} />
                <Route
                  path="/sms-template/:id"
                  element={
                    <Frame>
                      <SMSTemplateDetailsPage />
                    </Frame>} />
                <Route
                  path="/dnsp-approvals"
                  element={
                    <Frame>
                      <DnspApprovalsPage />
                    </Frame>} />
                <Route
                  path="/dnsp-approval/create"
                  element={
                    <Frame>
                      <DnspApprovalCreatePage />
                    </Frame>} />
                <Route
                  path="/dnsp-approval/:id"
                  element={
                    <Frame>
                      <DnspApprovalDetailsPage />
                    </Frame>} />

              </Routes>
            </Wrapper>
          </SnackbarProvider>
        </React.Suspense>
      </BrowserRouter>
    </AuthenticationGuard>
  );
}

export default AppRoutes;

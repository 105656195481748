import { View, Image, useTheme } from "@aws-amplify/ui-react";

export const AuthenticatorHeader = () => {
  const { tokens } = useTheme();
  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Image alt="Pure Electric logo" src="/pure-portal-logo.svg" width="75%" />
    </View>
  );
};

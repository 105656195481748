import { TextField, TextFieldProps, styled } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

const NumberInputWithoutSpinner = styled(TextField)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
  "& input[type=number]": { MozAppearance: "textfield" }
}));

export const CustomNumberInput = (props: {
  value: number;
  integerOnly?: boolean;
  onlyChangeOnBlur?: boolean;
  onChange?: (value: number) => void;
  textFieldProps?: TextFieldProps;
}) => {
  const processNumber = useCallback((val: number) => val.toFixed(props.integerOnly ? 0 : 2), [props.integerOnly])
  
  const ref = useRef<HTMLInputElement>(null);

  const [stringValue, setStringValue] = useState(props.value.toFixed(props.integerOnly ? 0 : 2));

  useEffect(() => {
    if (Number(stringValue) !== Number(processNumber(props.value))) {
      setStringValue(processNumber(props.value))
    }
  }, [props.value, processNumber]);

  const emitValue = () => {
    if (ref.current && Number(ref.current.value) !== Number(processNumber(props.value))) {
      if (props.integerOnly && /^\d+$/.test(ref.current.value) || ref.current.value === '') {
        setStringValue(ref.current.value)
        props.onChange?.(Number(ref.current.value))
      }
      else if (/^-?\d*\.?\d*$/.test(ref.current.value) || ref.current.value === '') {
        setStringValue(ref.current.value)
        props.onChange?.(Number(ref.current.value))
      }
    }
  }
  return <NumberInputWithoutSpinner
    type='tel'
    fullWidth
    onKeyDown={ev => {
      if (ev.key === 'Enter') {
        emitValue()
      }
    }}
    onBlur={(ev) => {
      if (props.onlyChangeOnBlur) {
        emitValue();
      }
      // Format the value on blur
      if (stringValue === '' || stringValue === '.') {
        setStringValue('0');
      } else {
        setStringValue(processNumber(props.value)); // Remove unnecessary leading zeros
      }
    }}
    disabled={!Boolean(props.onChange)}
    value={stringValue}
    onChange={ev => {
      // Allow digits, a single decimal point, and empty string
      if (!props.onlyChangeOnBlur) {
        emitValue()
      } else {
        setStringValue(ev.target.value)
      }
    }}
    {...(props.textFieldProps ?? {})}
    inputRef={ref} />
}
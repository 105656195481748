import { createRef, useEffect } from "react";
import {
  Authenticator,
  VisuallyHidden,
  CheckboxField,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import ReCAPTCHA from "react-google-recaptcha";
import { ReCaptchaV2 } from "./ReCaptchaV2";

export const SignupFormFields = () => {
  const { validationErrors } = useAuthenticator();
  const checkBoxRef = createRef<HTMLInputElement>();
  const recaptchaRef = createRef<ReCAPTCHA>();

  useEffect(() => {
    recaptchaRef.current && recaptchaRef.current.reset();
  }, []);

  return (
    <>
      {/* Re-use default `Authenticator.SignUp.FormFields` */}
      <Authenticator.SignUp.FormFields />

      {/* Append & require Terms & Conditions field to sign up  */}
      <CheckboxField
        errorMessage={validationErrors["acknowledgement"] as string}
        hasError={!!validationErrors["acknowledgement"]}
        name="acknowledgement"
        value="yes"
        label="I agree with the Terms & Conditions"
      />

      {/* Append GG ReCaptcha V2 */}
      <ReCaptchaV2
        ref={recaptchaRef}
        onExpired={() =>
          checkBoxRef.current &&
          checkBoxRef.current.checked &&
          checkBoxRef.current.click()
        }
        onChange={(value: any) =>
          value && checkBoxRef.current && checkBoxRef.current.click()
        }
        error={validationErrors["mycaptcha"] as string}
      />
      <VisuallyHidden>
        <CheckboxField
          ref={checkBoxRef}
          name="mycaptcha"
          value="yes"
          label="My captcha"
          labelHidden={true}
        />
      </VisuallyHidden>
    </>
  );
};

import React from 'react';
import { ThemeProvider, Theme, CssBaseline } from '@mui/material';
import { SuspenseFallbackView } from './SuspenseFallbackView';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity
    }
  }
});


export const Wrapper = (props: { children: React.ReactNode, theme: Theme }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={props.theme}>
        <CssBaseline />
        <React.Suspense fallback={<SuspenseFallbackView />}>
          {props.children}
        </React.Suspense>
      </ThemeProvider>
    </QueryClientProvider>
  );
};